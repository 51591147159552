import workcards from '../../data/dashboardCardList.json';
import workcardTasks from '../../data/workcardTaskList.json';
import type {
  Workcard,
  WorkcardTasks,
  Report,
  WorkcardTask,
  WorkcardOverviewTask,
  Plan,
  ControlMeasure,
  Risk,
  Greetings,
  RiskAssessmentQuestions,
  Execution,
  Redbox,
  PlanContent,
  WorkcardStatus,
  PaginatedRedbox,
  WorkcardLocation,
  Inspection,
  AuditLog    
} from '../../models';

import { baseQuery, createApi } from './base.service';

// Define a service using a base URL and expected endpoints
export const workcardService = createApi({
  reducerPath: 'workcardService',
  baseQuery: baseQuery,
  tagTypes: [
    'workcards',
    'workcard',
    'tasks',
    'greetings',
    'inspection',
    'execution',
    'redbox',
    'endshift',
    'risk',
    'controlMeasure',
    'inspectionSection',
  ],
  endpoints: (builder) => ({
    getWorkcards: builder.query<
      Workcard[],
      {
        siteId: number;
        fromDate?: string;
        toDate?: string;
        shift?: string;
        userObjectId?: string;
        isAllCrew: boolean;
        isCurrentUserAssignedSupervisor: boolean;
        language: string;
      }
    >({
      query: (arg) => {
        const { siteId, fromDate, toDate, shift, userObjectId, isAllCrew, language, isCurrentUserAssignedSupervisor } =
          arg;
        const query = `Site/${siteId}/Workcard/v3?FromDate=${fromDate || ''}&ToDate=${toDate || ''}&Shift=${
          shift || ''
        }&UserObjectId=${
          userObjectId || ''
        }&IsAllCrew=${isAllCrew}&language=${language}&isCurrentUserAssignedSupervisor=${isCurrentUserAssignedSupervisor}`;

        return query;
      },
      providesTags: ['workcards'],
    }),
    getWorkcardOverviewTaskById: builder.query<WorkcardOverviewTask, { siteId: number; workcardId: number }>({
      query: (arg) => {
        const { siteId, workcardId } = arg;

        return `Site/${siteId}/Workcard/${workcardId}/taskoverview`;
      },
      providesTags: ['workcard'],
    }),
    getWorkcardById: builder.query<Workcard[], { siteId: number; workcardId: number }>({
      query: (arg) => {
        const { siteId, workcardId } = arg;

        return `Site/${siteId}/Workcard/${workcardId}`;
      },
      providesTags: ['workcard'],
    }),
    getWorkcardStatusById: builder.query<WorkcardStatus, { siteId: number; workcardId: number }>({
      query: (arg) => {
        const { siteId, workcardId } = arg;

        return `Site/${siteId}/Workcard/${workcardId}/status`;
      },
      providesTags: ['workcard'],
    }),
    getTasksByWorkcardId: builder.query<WorkcardTask[], { siteId: number; workcardId: number; lang: string }>({
      query: (arg) => {
        const { siteId, workcardId, lang } = arg;

        return `Site/${siteId}/Workcard/${workcardId}/Task?language=${lang}`;
      },
      providesTags: ['tasks'],
    }),
    getWorkcardTaskId: builder.query<WorkcardTask[], { siteId: number; workcardId: number; taskId: number }>({
      query: (arg) => {
        const { siteId, workcardId, taskId } = arg;

        return `Site/${siteId}/Workcard/${workcardId}/Task/${taskId}`;
      },
      providesTags: ['tasks'],
    }),
    updateTasksOrder: builder.mutation({
      query: ({ siteId, workcardId, payload }) => ({
        url: `Site/${siteId}/Workcard/${workcardId}/Task/taskorder`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['tasks'],
    }),
    getWorkcardTaskInpsection: builder.query<
      RiskAssessmentQuestions,
      { siteId: number; workcardId: number; taskId: number; lang: string }
    >({
      query: (arg) => {
        const { siteId, workcardId, taskId, lang } = arg;

        return `Site/${siteId}/Workcard/${workcardId}/Task/${taskId}/Inspection?language=${lang}`;
      },
      providesTags: ['inspection'],
    }),
    copyWorkerAnswerInpsectionBySupervisor: builder.mutation({
      query: ({ siteId, workcardId, taskId }) => ({
        url: `Site/${siteId}/Workcard/${workcardId}/Task/${taskId}/Inspection/copyworkeranswerBySupervisor`,
        method: 'POST',
      }),
      invalidatesTags: ['inspection'],
    }),
    updateWorkcardTaskInpsection: builder.mutation({
      query: ({ siteId, workcardId, taskId, questionId, payload }) => ({
        url: `Site/${siteId}/Workcard/${workcardId}/Task/${taskId}/Inspection/question/${questionId}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['inspection'],
    }),
    postWorkcardTaskInpsectionSignature: builder.mutation({
      query: ({ siteId, workcardId, taskId, payload }) => ({
        url: `Site/${siteId}/Workcard/${workcardId}/Task/${taskId}/Inspection/signature`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['inspection'],
    }),
    getWorkcardTaskPlanSummary: builder.query<WorkcardTask[], { siteId: number; workcardId: number; taskId: number }>({
      query: (arg) => {
        const { siteId, workcardId, taskId } = arg;

        return `Site/${siteId}/Workcard/${workcardId}/Task/plan/summary`;
      },
      providesTags: ['inspection'],
    }),
    getWorkcardPlan: builder.query<Plan[], { siteId: number; workcardId: number; lang: string }>({
      query: (arg) => {
        const { siteId, workcardId, lang } = arg;

        return `Site/${siteId}/Workcard/${workcardId}/plan?language=${lang}`;
      },
      providesTags: ['inspection'],
    }),
    getWorkcardTaskPlan: builder.query<
      PlanContent,
      { siteId: number; workcardId: number; taskId: number; lang: string }
    >({
      query: (arg) => {
        const { siteId, workcardId, taskId, lang } = arg;

        return `Site/${siteId}/Workcard/${workcardId}/Task/${taskId}/plan?language=${lang}`;
      },
      providesTags: ['inspection'],
    }),
    getWorkcardTaskPlanId: builder.query<
      WorkcardTask[],
      { siteId: number; workcardId: number; taskId: number; planId: number }
    >({
      query: (arg) => {
        const { siteId, workcardId, taskId, planId } = arg;

        return `Site/${siteId}/Workcard/${workcardId}/Task/${taskId}/plan/${planId}`;
      },
    }),
    updateWorkcardPlan: builder.mutation({
      query: ({ siteId, workcardId, taskId, planId, payload }) => ({
        url: `Site/${siteId}/Workcard/${workcardId}/Task/${taskId}/Plan/${planId}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['inspection'],
    }),
    deleteWorkcardPlan: builder.mutation({
      query: ({ siteId, workcardId, taskId, planId, payload }) => ({
        url: `Site/${siteId}/Workcard/${workcardId}/Task/${taskId}/Plan/${planId}`,
        method: 'DELETE',
        body: payload,
      }),
      invalidatesTags: ['inspection'],
    }),
    postWorkcardPlan: builder.mutation({
      query: ({ siteId, workcardId, taskId, planId, payload }) => ({
        url: `Site/${siteId}/Workcard/${workcardId}/Task/${taskId}/Plan`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['inspection'],
    }),
    updateWorkcardDiscussionPlan: builder.mutation({
      query: ({ siteId, workcardId, payload }) => ({
        url: `Site/${siteId}/Workcard/${workcardId}/planification`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['inspection'],
    }),
    getWorkcardDiscussionPlan: builder.query<Greetings, { siteId: number; workcardId: number }>({
      query: (arg) => {
        const { siteId, workcardId } = arg;

        return `Site/${siteId}/Workcard/${workcardId}/planification`;
      },
      providesTags: ['inspection'],
    }),
    getWorkcardGreetings: builder.query<Greetings, { siteId: number; workcardId: number }>({
      query: (arg) => {
        const { siteId, workcardId } = arg;

        return `Site/${siteId}/Workcard/${workcardId}/greetings`;
      },
      providesTags: ['greetings'],
    }),
    updateGreetings: builder.mutation({
      query: ({ siteId, workcardId, payload }) => ({
        url: `Site/${siteId}/Workcard/${workcardId}/greetings`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['greetings'],
    }),
    postGreetingSignature: builder.mutation({
      query: ({ siteId, workcardId, payload }) => ({
        url: `Site/${siteId}/Workcard/${workcardId}/greetings/signature`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['greetings', 'workcard'],
    }),
    getControlMeasureList: builder.query<ControlMeasure[], { siteId: number; language: string }>({
      query: (arg) => {
        const { siteId, language } = arg;
        return `Site/${siteId}/ControlMeasure?language=${language}`;
      },
      providesTags: ['controlMeasure'],
    }),
    updateControlMeasure: builder.mutation({
      query: ({ siteId, payload }) => ({
        url: `Site/${siteId}/ControlMeasure`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['controlMeasure'],
    }),
    postControlMeasure: builder.mutation({
      query: ({ siteId, payload }) => ({
        url: `Site/${siteId}/ControlMeasure`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['controlMeasure'],
    }),
    postControlMeasureTranslation: builder.mutation({
      query: ({ siteId, cmid, payload }) => ({
        url: `Site/${siteId}/ControlMeasure/${cmid}/translation`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['controlMeasure'],
    }),
    updateControlMeasureTranslation: builder.mutation({
      query: ({ siteId, cmid, id, payload }) => ({
        url: `Site/${siteId}/ControlMeasure/${cmid}/translation/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['controlMeasure'],
    }),
    getRiskList: builder.query<Risk[], { siteId: number; language: string }>({
      query: (arg) => {
        const { siteId, language } = arg;
        return `Site/${siteId}/Risk?language=${language}`;
      },
      providesTags: ['risk'],
    }),
    updateRisk: builder.mutation({
      query: ({ siteId, payload }) => ({
        url: `Site/${siteId}/Risk`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['risk'],
    }),
    postRisk: builder.mutation({
      query: ({ siteId, payload }) => ({
        url: `Site/${siteId}/Risk`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['risk'],
    }),
    postRiskTranslation: builder.mutation({
      query: ({ siteId, riskId, payload }) => ({
        url: `Site/${siteId}/Risk/${riskId}/translation`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['risk'],
    }),
    updateRiskTranslation: builder.mutation({
      query: ({ siteId, riskId, id, payload }) => ({
        url: `Site/${siteId}/Risk/${riskId}/translation/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['risk'],
    }),
    getWorkcardExecution: builder.query<Execution, { siteId: number; workcardId: number }>({
      query: (arg) => {
        const { siteId, workcardId } = arg;

        return `Site/${siteId}/Workcard/${workcardId}/execution`;
      },
      providesTags: ['execution'],
    }),
    updateWorkcardExecution: builder.mutation({
      query: ({ siteId, workcardId, payload }) => ({
        url: `Site/${siteId}/Workcard/${workcardId}/execution`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['execution'],
    }),
    postWorkcardExecutionSignature: builder.mutation({
      query: ({ siteId, workcardId, payload }) => ({
        url: `Site/${siteId}/Workcard/${workcardId}/execution/signature`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['execution'],
    }),
    getWorkcardEndshift: builder.query<Report, { siteId: number; workcardId: number }>({
      query: (arg) => {
        const { siteId, workcardId } = arg;

        return `Site/${siteId}/Workcard/${workcardId}/endshift`;
      },
      providesTags: ['endshift'],
    }),
    updateWorkcardEndshift: builder.mutation({
      query: ({ siteId, workcardId, payload }) => ({
        url: `Site/${siteId}/Workcard/${workcardId}/endshift`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['endshift'],
    }),
    postWorkcardEndshiftSignature: builder.mutation({
      query: ({ siteId, workcardId, payload }) => ({
        url: `Site/${siteId}/Workcard/${workcardId}/endshift/signature`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['endshift'],
    }),
    getRedbox: builder.query<PaginatedRedbox, { siteId: number; pageSize: number; pageIndex: number }>({
      query: (arg) => {
        const { siteId, pageSize, pageIndex } = arg;

        return `Site/${siteId}/workcard/redbox?pageSize=${pageSize}&pageIndex=${pageIndex}`;
      },
      providesTags: ['redbox'],
    }),
    getWorkcardRedbox: builder.query<Redbox[], { siteId: number; workcardId: number }>({
      query: (arg) => {
        const { siteId, workcardId } = arg;

        return `Site/${siteId}/Workcard/${workcardId}/redbox`;
      },
      providesTags: ['redbox'],
    }),
    getWorkcardInspectionSection: builder.query<Inspection, { siteId: number; workcardId: number }>({
      query: (arg) => {
        const { siteId, workcardId } = arg;

        return `Site/${siteId}/Workcard/${workcardId}/inspectionsection`;
      },
      providesTags: ['inspectionSection'],
    }),
    updateWorkcardInpsectionSection: builder.mutation({
      query: ({ siteId, workcardId, payload }) => ({
        url: `Site/${siteId}/Workcard/${workcardId}/inspectionsection`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['inspectionSection'],
    }),
    postWorkcardRedbox: builder.mutation({
      query: ({ siteId, workcardId, payload }) => ({
        url: `Site/${siteId}/Workcard/${workcardId}/redbox`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['redbox'],
    }),
    postWorkcardRedboxSignature: builder.mutation({
      query: ({ siteId, workcardId, redboxId, payload }) => ({
        url: `Site/${siteId}/Workcard/${workcardId}/redbox/${redboxId}/signature`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['redbox'],
    }),
    deleteWorkcardRedboxSignature: builder.mutation({
      query: ({ siteId, workcardId, redboxId, signatureId, payload }) => ({
        url: `Site/${siteId}/Workcard/${workcardId}/redbox/${redboxId}/signature/${signatureId}`,
        method: 'DELETE',
        body: payload,
      }),
      invalidatesTags: ['redbox'],
    }),
    getWorkcardLocation: builder.query<WorkcardLocation[], { siteId: number }>({
      query: (arg) => {
        const { siteId } = arg;

        return `Site/${siteId}/Location`;
      },
    }),
    assignSupervisorToWorkcard: builder.mutation({
      query: ({ siteId, workcardId, payload }) => ({
        url: `Site/${siteId}/Workcard/${workcardId}/assignedsupervisor`,
        method: 'PATCH',
        body: payload,
      }),
    }),
    unassignSupervisorToWorkcard: builder.mutation({
      query: ({ siteId, workcardId, userobjectId }) => ({
        url: `Site/${siteId}/Workcard/${workcardId}/assignedsupervisor?userobjectId=${userobjectId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetRedboxQuery,
  useGetWorkcardsQuery,
  useLazyGetWorkcardsQuery,
  useGetWorkcardByIdQuery,
  useGetWorkcardStatusByIdQuery,
  useGetTasksByWorkcardIdQuery,
  useGetWorkcardTaskIdQuery,
  useUpdateTasksOrderMutation,
  useGetWorkcardExecutionQuery,
  useUpdateWorkcardExecutionMutation,
  useCopyWorkerAnswerInpsectionBySupervisorMutation,
  useGetWorkcardGreetingsQuery,
  useGetWorkcardOverviewTaskByIdQuery,
  useGetWorkcardTaskPlanSummaryQuery,
  useGetWorkcardDiscussionPlanQuery,
  useGetWorkcardPlanQuery,
  useGetWorkcardTaskPlanQuery,
  useGetWorkcardTaskPlanIdQuery,
  useGetControlMeasureListQuery,
  useUpdateControlMeasureMutation,
  usePostControlMeasureMutation,
  usePostControlMeasureTranslationMutation,
  useUpdateControlMeasureTranslationMutation,
  useGetRiskListQuery,
  useUpdateRiskMutation,
  usePostRiskMutation,
  usePostRiskTranslationMutation,
  useUpdateRiskTranslationMutation,
  useGetWorkcardRedboxQuery,
  usePostWorkcardRedboxMutation,
  useUpdateWorkcardDiscussionPlanMutation,
  usePostWorkcardRedboxSignatureMutation,
  useDeleteWorkcardRedboxSignatureMutation,
  useGetWorkcardEndshiftQuery,
  useUpdateWorkcardEndshiftMutation,
  usePostWorkcardEndshiftSignatureMutation,
  useGetWorkcardTaskInpsectionQuery,
  useUpdateGreetingsMutation,
  useUpdateWorkcardPlanMutation,
  usePostWorkcardPlanMutation,
  useDeleteWorkcardPlanMutation,
  usePostWorkcardExecutionSignatureMutation,
  useUpdateWorkcardTaskInpsectionMutation,
  usePostWorkcardTaskInpsectionSignatureMutation,
  usePostGreetingSignatureMutation,
  useGetWorkcardLocationQuery,
  useAssignSupervisorToWorkcardMutation,
  useUnassignSupervisorToWorkcardMutation,
  useGetWorkcardInspectionSectionQuery,
  useUpdateWorkcardInpsectionSectionMutation,
} = workcardService;
