
import type { AuditLog } from "../../../../models";
import './AuditLogItem.scss';

export const AuditLogItem: React.FC<{ logs: AuditLog[] | undefined }> = ({ logs }) => {
    const format = new Intl.DateTimeFormat(['fr', 'en', 'fi'], { year: "numeric", month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" })
    return (
        <table className="min-w-full divide-y divide-gray-300">
            <thead className="">
                <tr>
                    <th
                        scope="col"
                        className="w-[5%] py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-quartery-default sm:pl-6"
                    >
                        Timestamp
                    </th>
                    <th
                        scope="col"
                        className="w-[15%] py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-quartery-default sm:pl-6"
                    >
                        Operation
                    </th>
                    <th
                        scope="col"
                        className="w-[15%] py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-quartery-default sm:pl-6"
                    >
                        Name
                    </th>
                    <th
                        scope="col"
                        className="w-[65%] py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-quartery-default sm:pl-6"
                    >
                        Log description
                    </th>
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white dark:bg-secondary-default">
                {
                    logs?.map((item: AuditLog, index) => (
                        <tr key={index}>
                            <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-semibold text-gray-900 dark:text-quartery-default sm:w-auto sm:max-w-none sm:pl-6">
                                {format.format(new Date(item.timestamp))}
                            </td>
                            <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-semibold text-gray-900 dark:text-gray-400 sm:w-auto sm:max-w-none sm:pl-6">
                                {item.operation}
                            </td>
                            <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-semibold text-gray-900 dark:text-gray-400 sm:w-auto sm:max-w-none sm:pl-6">
                                {item.employee}
                            </td>
                            <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-semibold text-gray-900 dark:text-gray-400 sm:w-auto sm:max-w-none sm:pl-6">
                                {item.description}
                            </td>
                        </tr>
                    ))}

            </tbody>
        </table>
    );

}