import type { AuditLog } from '../../models';

import { baseQuery, createApi } from './base.service';

export const auditlogService = createApi(
    {
        reducerPath: 'AuditLogService',
        baseQuery: baseQuery,
        tagTypes:['auditlogservice'],
        endpoints: (builder) => ({
            getAuditLogByWorkcardId: builder.query<AuditLog[],{workcardId:number}>({
                query: (arg) => {
                    const { workcardId} = arg;
                    return `Auditlogs?workcardId=${workcardId}`;
                },
            }),
        }),

    }
);

export const {
    useGetAuditLogByWorkcardIdQuery,
} = auditlogService;