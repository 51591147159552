import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonMenu,
    IonRefresher,
    IonRefresherContent,
    useIonViewWillEnter,
  } from '@ionic/react';
import type { RefresherEventDetail } from '@ionic/react';
import React, { useState, Suspense, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { useAppSelector } from '../../hooks/hooks';
import { Content } from '../../layout/content/content';
import { Header } from '../../layout/header/Header/Header';
import { useGetAuditLogByWorkcardIdQuery } from '../../services/data/auditlog.service';

import { Auditfilter } from './components/AuditFilter/AuditFilter';
import { AuditLogItem } from './components/AuditLogItem/AuditLogItem';

import './AuditLog.scss';


export const AuditLog: React.FC = () => {

    const { id } = useParams<{ id: string }>();
    const { data, error, isLoading, isFetching, refetch} = useGetAuditLogByWorkcardIdQuery({workcardId:+id});

    useIonViewWillEnter(() => {
        refetch();
    });

    return (
        <Content isVerticalScroll={true} header={<Header title="Audit log" hasProfile={true} backBtn={true} />}>
            <div className=" px-5 sm:px-0 sm:ml-[40px] mt-5 mr-5">
                <AuditLogItem logs={data}></AuditLogItem>
            </div>
        </Content>
    );
};  